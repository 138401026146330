import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Tasks from './components/TaskList';
import Frens from './components/Frens';
import Farm from './components/Farm';
import NavBar from './components/NavBar';
import AdminPage from './components/AdminPage.js';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const [user_id, setUserId] = useState(null);

  useEffect(() => {
    // Проверка доступности Telegram Web App SDK
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      const userId = tg.initDataUnsafe?.user?.id;
      setUserId(userId);
      tg.expand();

      // Устанавливаем цвет заголовка
      if (tg.setHeaderColor) {
        tg.setHeaderColor("#000000");
      }
    }
  }, []);

  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home user_id={user_id} />} />
        <Route path="/tasks" element={<Tasks user_id={user_id} />} />
        <Route path="/frens" element={<Frens user_id={user_id} />} />
        <Route path="/farm" element={<Farm user_id={user_id} />} />
        <Route path="/exit" element={<AdminPage user_id={user_id} />} />
      </Routes>
    </Router>
  );
};

export default App;
