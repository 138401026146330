import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem } from '@fortawesome/free-solid-svg-icons';
import config from '../config';

const Container = styled.div`
  padding: 20px;
  color: #fff;
  text-align: center;
`;

const FrensCountContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const FrensCount = styled.div`
  font-size: 36px;
  font-weight: bold;
`;

const FriendsContainer = styled.div`
  background: #1A1A1A;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
`;

const FriendItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #444;
`;

const FriendInfo = styled.div`
  display: flex;
  align-items: center;
`;

const FriendAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const FriendName = styled.div`
  font-size: 16px;
`;

const FriendPoints = styled.div`
  font-size: 16px;
  color: #FFF;
`;

const InviteButton = styled.button`
  background-color: #6200ee;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;

  &:hover {
    background-color: #3700b3;
  }
`;

const Notification = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #336633;
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 0.5s;
`;

const Frens = ({ user_id }) => {
  const [friends, setFriends] = useState([]);
  const [notificationVisible, setNotificationVisible] = useState(false);

  useEffect(() => {
    const fetchFriends = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}friends/${user_id}`);
        setFriends(response.data);
      } catch (error) {
        console.error('Error fetching friends:', error);
      }
    };

    if (user_id) {
      fetchFriends();
    }
  }, [user_id]);

  const handleInviteClick = () => {
    const inviteLink = `https://t.me/Gems_Farm_Bot?start=r_${user_id}`;
    navigator.clipboard.writeText(inviteLink).then(() => {
      setNotificationVisible(true);
      setTimeout(() => {
        setNotificationVisible(false);
      }, 3000);
    });
  };

  return (
    <Container>
      <Notification visible={notificationVisible}>Copied!</Notification>
      <FrensCountContainer>
        <FrensCount>{friends.length}</FrensCount>
      </FrensCountContainer>
      <FriendsContainer>
        <div>Fellow Friends</div>
        {friends.map(friend => (
          <FriendItem key={friend.user_id}>
            <FriendInfo>
              <FriendAvatar src={`//bot.gemsfarm.online/avatars/${friend.user_id}.jpg`} alt={friend.user_username} />
              <FriendName>{friend.user_username}</FriendName>
            </FriendInfo>
            <FriendPoints>{friend.gems} <FontAwesomeIcon icon={faGem} /></FriendPoints>
          </FriendItem>
        ))}
      </FriendsContainer>
      <InviteButton onClick={handleInviteClick}>+ Invite friends</InviteButton>
    </Container>
  );
};

export default Frens;
