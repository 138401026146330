const logLevel = process.env.REACT_APP_LOG_LEVEL || 'info';

const levels = {
  error: 0,
  warn: 1,
  info: 2,
  debug: 3,
};

const logger = {
  log: (level, message) => {
    if (levels[level] <= levels[logLevel]) {
      console[level](message);
    }
  },
  error: (message) => logger.log('error', message),
  warn: (message) => logger.log('warn', message),
  info: (message) => logger.log('info', message),
  debug: (message) => logger.log('debug', message),
};

export default logger;
