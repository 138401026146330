// TaskList.js
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TaskCard from './TaskCard';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem } from '@fortawesome/free-solid-svg-icons';
import config from '../config';

const Container = styled.div`
  padding: 15px;
  margin-bottom: 50px;
`;

const ContainerInfo = styled.div`
  background: #000;
  padding: 15px;
  text-align: center;
`;

const Icon = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const Points = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #FFF;
`;

const List = styled.div`
  padding: 10px;
`;

const TaskList = ({ user_id }) => {
  const [pendingTasks, setPendingTasks] = useState([]);
  const [doneTasks, setDoneTasks] = useState([]);
  const [gems, setGems] = useState(0);
  const [runTasks, setRunTasks] = useState([]);

  useEffect(() => {
    axios.get(`${config.apiUrl}tasks/${user_id}`)
      .then(response => {
        setPendingTasks(response.data.pendingTasks);
        setDoneTasks(response.data.doneTasks);
      })
      .catch(error => {
        console.error('Error fetching tasks:', error);
      });

    axios.get(`${config.apiUrl}user/${user_id}`)
      .then(response => {
        setGems(response.data.gems);
      })
      .catch(error => {
        console.error('Error fetching user gems:', error);
      });

    axios.post(`${config.apiUrl}run_tasks`, { user_id })
      .then(response => {
        setRunTasks(response.data);
      })
      .catch(error => {
        console.error('Error fetching run tasks:', error);
      });
  }, [user_id]);

  const refreshGems = () => {
    axios.get(`${config.apiUrl}user/${user_id}`)
      .then(response => {
        setGems(response.data.gems);
      })
      .catch(error => {
        console.error('Error refreshing user gems:', error);
      });
  };

  return (
    <Container>
      <ContainerInfo>
        <Icon src={`//bot.gemsfarm.online/avatars/${user_id}.jpg`} />
        <Points>{gems} <FontAwesomeIcon icon={faGem} /></Points>
      </ContainerInfo>

      <h3>New Tasks ({pendingTasks.length})</h3>
      <List>
        {pendingTasks.map((task, index) => (
          <TaskCard 
            key={index} 
            user_id={user_id}
            icon={`//bot.gemsfarm.online/bots_logo/${task.link_uri}.jpg`}
            name={task.link_name} 
            description={task.link_description} 
            link_url={task.link_url}
            link_uri={task.link_uri}  
            reward={task.reward} 
            points={task.reward}
            completed={false}
            runTask={runTasks.includes(task.link_uri)}
            onClaim={refreshGems}
          />
        ))}
      </List>

      <h3>Completed Tasks ({doneTasks.length})</h3>
      <List>
        {doneTasks.map((task, index) => (
          <TaskCard 
            key={index} 
            user_id={user_id}
            icon={`//bot.gemsfarm.online/bots_logo/${task.link_uri}.jpg`}
            name={task.link_name} 
            description={task.link_description} 
            link_url={task.link_url}
            link_uri={task.link_uri}  
            reward={task.reward} 
            points={task.reward}
            completed={true}
            runTask={false}
            onClaim={refreshGems}
          />
        ))}
      </List>
    </Container>
  );
};

export default TaskList;

