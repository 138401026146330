// Farm.js
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FarmCard from './FarmCard';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem } from '@fortawesome/free-solid-svg-icons';
import config from '../config';

const Container = styled.div`
  padding: 15px;
  margin-bottom: 50px;
`;

const ContainerInfo = styled.div`
  background: #000;
  padding: 15px;
  text-align: center;
`;

const Icon = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const Points = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #FFF;
`;

const SectionTitle = styled.h3`
  margin-top: 20px;
`;

const List = styled.div`
  padding: 10px;
`;

const Farm = ({ user_id }) => {
  const [readyToFarm, setReadyToFarm] = useState([]);
  const [recentlyCollected, setRecentlyCollected] = useState([]);
  const [noGlobalTimer, setNoGlobalTimer] = useState([]);
  const [gems, setGems] = useState(0);

  // Функция для загрузки данных
  const fetchFarmData = () => {
    const telegramInitData = window.Telegram.WebApp.initData;

    axios.get(`${config.apiUrl}farm/${user_id}`, {
      params: { telegramInitData }
    })
      .then(response => {
        setReadyToFarm(response.data.readyToFarm);
        setRecentlyCollected(response.data.recentlyCollected);
        setNoGlobalTimer(response.data.noGlobalTimer);
      })
      .catch(error => {
        console.error('Error fetching farm tasks:', error);
      });

    axios.get(`${config.apiUrl}user/${user_id}`, {
      params: { telegramInitData }
    })
      .then(response => {
        setGems(response.data.gems);
      })
      .catch(error => {
        console.error('Error fetching user gems:', error);
      });
  };

  useEffect(() => {
    // Загрузка данных при первом рендере
    fetchFarmData();

    // Устанавливаем интервал для обновления данных каждые 10 секунд
    const interval = setInterval(fetchFarmData, 10000);

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(interval);
  }, [user_id]);

  return (
    <Container>
      <ContainerInfo>
        <Icon src={`//bot.gemsfarm.online/avatars/${user_id}.jpg`} />
        <Points>{gems} <FontAwesomeIcon icon={faGem} /></Points>
      </ContainerInfo>

      <SectionTitle>Ready to Farm</SectionTitle>
      <List>
        {readyToFarm.map((task, index) => (
          <FarmCard
          key={index}
          user_id={user_id}
          icon={`//bot.gemsfarm.online/bots_logo/${task.link_uri}.jpg`}
          name={task.link_name}
          description={task.link_description}
          link_url={task.link_url}
          link_uri={task.link_uri}
          reward={task.reward}
          last_sec={task.last_sec}
          global_timer_hr={task.global_timer_hr}
          />
        ))}
      </List>

      <SectionTitle>Recently Collected</SectionTitle>
      <List>
        {recentlyCollected.map((task, index) => (
          <FarmCard
          key={index}
          user_id={user_id}
          icon={`//bot.gemsfarm.online/bots_logo/${task.link_uri}.jpg`}
          name={task.link_name}
          description={task.link_description}
          link_url={task.link_url}
          link_uri={task.link_uri}
          reward={task.reward}
          last_sec={task.last_sec}
          global_timer_hr={task.global_timer_hr}
          />
        ))}
      </List>

      <SectionTitle>No Global Timer</SectionTitle>
      <List>
        {noGlobalTimer.map((task, index) => (
          <FarmCard
            key={index}
            user_id={user_id}
            icon={`//bot.gemsfarm.online/bots_logo/${task.link_uri}.jpg`}
            name={task.link_name}
            description={task.link_description}
            link_url={task.link_url}
            link_uri={task.link_uri}
            reward={task.reward}
            last_sec={task.last_sec}
            global_timer_hr={task.global_timer_hr}
          />
        ))}
      </List>
    </Container>
  );
};

export default Farm;
