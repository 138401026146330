// FarmCard.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import axios from 'axios';
import config from '../config';

const Card = styled.div`
  background: #1a1a1a;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TaskInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Выравнивание по левому краю */
  flex-grow: 1;
`;

const TaskText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
`;

const TaskName = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const TaskDescription = styled.span`
  font-size: 14px;
  color: #ccc;
`;

const TimePassed = styled.span`
  font-size: 12px;
  margin-top: 5px;
  color: ${({ timeColor }) => timeColor || '#888'}; /* Изменяем цвет */
`;

const TimerInfo = styled.span`
  font-size: 12px;
  color: #888;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; /* Выравнивание по правому краю */
  flex-shrink: 0; /* Не сжимать контейнер кнопок */
`;

const Button = styled.button`
  background: ${({ color }) => color || '#333'};
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  margin-left: 10px; /* Отступ между кнопками */
`;

const Notification = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #336633;
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 0.5s;
`;

const FarmCard = ({ user_id, icon, name, description, link_url, link_uri, reward, last_sec, global_timer_hr }) => {
  const [notificationVisible, setNotificationVisible] = useState(false);

  const handleRefClick = () => {
    setNotificationVisible(true);
    setTimeout(() => {
      setNotificationVisible(false);
    }, 3000);
  };

  const handleGoClick = () => {
    const actionData = moment().format('YYYY-MM-DD HH:mm:ss');
    const sec = moment().unix();
    axios.post(`${config.apiUrl}log_action`, {
      user_id,
      action_type: 'click',
      value: 0,
      target: link_uri,
      action_data: actionData,
      sec: sec
    })
    .then(() => {
      window.open(link_url, '_blank');
    })
    .catch(error => {
      console.error('Error logging action:', error);
    });
  };

  // Вычисляем и форматируем время, прошедшее с последнего действия
  const timePassed = last_sec ? moment.unix(last_sec).fromNow() : 'Never';
  const secondsPassed = last_sec ? moment().unix() - last_sec : 0;
  const hoursPassed = secondsPassed / 3600;

  // Определяем цвет в зависимости от времени, прошедшего с последнего действия
  let timeColor;
  if (global_timer_hr > 0) {
    if (hoursPassed < global_timer_hr) {
      timeColor = '#336633';
    } else if (hoursPassed < global_timer_hr * 2) {
      timeColor = 'yellow';
    } else {
      timeColor = '#660099';
    }
  }

  return (
    <Card>
      <TaskInfo>
        <Icon src={icon} alt="icon" />
        <TaskText>
          <TaskName>{name}</TaskName>
          <TimerInfo>Farm Timer: {global_timer_hr || 'Not set'} hours</TimerInfo>
          <TimePassed timeColor={timeColor}>Last Farm: {timePassed}</TimePassed>
        </TaskText>
      </TaskInfo>
      <ButtonContainer>
        <Button color="#336633" onClick={handleRefClick}>Ref</Button>
        <Button color="#660099" onClick={handleGoClick}>Go</Button>
      </ButtonContainer>
      <Notification visible={notificationVisible}>Coming soon</Notification>
    </Card>
  );
};

export default FarmCard;

