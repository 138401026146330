// TaskCard.js
import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem } from '@fortawesome/free-solid-svg-icons';
import config from '../config';

// Анимация лоадера
const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 4px solid #333; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${rotate} 1s linear infinite;
`;

const Card = styled.div`
  background: #1a1a1a;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Делаем ширину 100% */
`;

const TaskInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
`;

const TaskText = styled.div`
  display: flex;
  flex-direction: column;
`;

const TaskName = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const TaskDescription = styled.span`
  font-size: 14px;
  color: #ccc;
`;

const Button = styled.button`
  background: ${({ disabled, buttonState }) => {
    if (disabled) return '#333';
    if (buttonState === 'claim') return '#660099';
    if (buttonState === 'loading') return '#336633';
    return '#336633';
  }};
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  color: #fff;
  font-weight: bold;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    right: 10px;
  }
`;

const TaskCard = ({ user_id, icon, name, description, link_url, link_uri, reward, completed, runTask, onClaim }) => {
  const [buttonState, setButtonState] = useState(completed ? 'done' : (runTask ? 'claim' : 'start')); // start, loading, claim, done
  const [disabled, setDisabled] = useState(completed);

  const handleStart = () => {
    console.log(`Starting task with link_uri: ${link_uri}`); // Логирование link_uri
    setButtonState('loading');
    setDisabled(true);

    // Логирование действия "run_task"
    axios.post(`${config.apiUrl}log_action`, {
      user_id,
      action_type: 'run_task',
      value: 0,
      target: link_uri,
      action_data: moment().format('YYYY-MM-DD HH:mm:ss'),
      sec: moment().unix()
    })
    .then(() => {
      // Обновление количества кликов
      return axios.post(`${config.apiUrl}update_clicks`, {
        link_uri
      });
    })
    .then(() => {
      window.open(link_url, '_blank');
      setTimeout(() => {
        setButtonState('claim');
        setDisabled(false);
      }, 15000);
    })
    .catch((error) => {
      console.error('Error logging action or updating clicks:', error);
      setButtonState('start');
      setDisabled(false);
    });
  };

  const handleClaim = () => {
    console.log(`Claiming task with link_uri: ${link_uri}`); // Логирование link_uri
    setButtonState('done');
    setDisabled(true);

    axios.post(`${config.apiUrl}log_action`, {
      user_id,
      action_type: 'claim',
      value: reward,
      target: link_uri,
      action_data: moment().format('YYYY-MM-DD HH:mm:ss'),
      sec: moment().unix()
    })
    .then(() => {
      return axios.post(`${config.apiUrl}update_gems`, {
        user_id,
        reward
      });
    })
    .then(() => {
      onClaim(); // Вызов обновления gems
    })
    .catch((error) => {
      console.error('Error claiming reward:', error);
      setButtonState('claim');
      setDisabled(false);
    });
  };

  const getButtonContent = () => {
    if (buttonState === 'loading') {
      return <Loader />;
    }
    return getButtonLabel();
  };

  const getButtonLabel = () => {
    switch (buttonState) {
      case 'start':
        return 'Start';
      case 'claim':
        return 'Claim';
      case 'done':
        return 'Done';
      default:
        return 'Start';
    }
  };

  return (
    <Card>
      <TaskInfo>
        <Icon src={icon} alt="icon" />
        <TaskText>
          <TaskName>{name}</TaskName>
          <TaskDescription>+{reward} <FontAwesomeIcon icon={faGem} />  {description}</TaskDescription>
        </TaskText>
      </TaskInfo>
      <Button
        disabled={disabled}
        buttonState={buttonState}
        onClick={buttonState === 'start' ? handleStart : buttonState === 'claim' ? handleClaim : null}
      >
        {getButtonContent()}
      </Button>
    </Card>
  );
};

export default TaskCard;
