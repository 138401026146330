import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem } from '@fortawesome/free-solid-svg-icons';
import logger from './clientLogger'; // Подключаем логгер
import TaskCard from './TaskCard'; // Подключаем компонент TaskCard
import { Link } from 'react-router-dom'; // Импортируем Link для навигации
import './Home.css'; // Подключаем CSS стили
import config from '../config';

function Home({ user_id }) {
  const [user, setUser] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState(null);
  const [hasMoreTasks, setHasMoreTasks] = useState(false); // Новое состояние для отслеживания наличия более 3 задач
  useEffect(() => {      
    
    if (user_id) {
      logger.info(`Fetching user with ID: ${user_id}`);

      axios.get(`${config.apiUrl}user/${user_id}`)
        .then(response => {
          logger.info('User data received:', response.data);

          const userData = response.data;
          if (userData.user_ava_local === 1) {
            userData.profile_picture = `//bot.gemsfarm.online/avatars/${user_id}.jpg`;
          } else {
            userData.profile_picture = `//bot.gemsfarm.online/avatars/noava.jpg`;
          }

          setUser(userData);
        })
        .catch(error => {
          logger.error('Error fetching user:', error);
          setError('User not found or an error occurred');
        });

      logger.info(`Fetching tasks for user ID: ${user_id}`);
      axios.get(`${config.apiUrl}tasks/${user_id}`)
        .then(response => {
          logger.info('Tasks data received:', response.data);

          const sortedTasks = response.data.pendingTasks
            .filter(task => !task.completed)
            .sort((a, b) => b.reward - a.reward || new Date(b.created_at) - new Date(a.created_at))
            .slice(0, 3);

          setTasks(sortedTasks);
          setHasMoreTasks(response.data.pendingTasks.filter(task => !task.completed).length > 3); // Проверяем, есть ли больше 3 задач
        })
        .catch(error => {
          logger.error('Error fetching tasks:', error);
          setError('Tasks not found or an error occurred');
        });
    }
  }, [user_id]);

  const handleClaim = () => {
    axios.get(`${config.apiUrl}user/${user_id}`)
      .then(response => {
        const updatedUser = response.data;
        if (updatedUser.user_ava_local === 1) {
          updatedUser.profile_picture = `//bot.gemsfarm.online/avatars/${user_id}.jpg`;
        } else {
          updatedUser.profile_picture = `//bot.gemsfarm.online/avatars/noava.jpg`;
        }
        setUser(updatedUser);
      })
      .catch(error => {
        console.error('Error updating user gems:', error);
      });
  };

  return (
    <div className="home-container">
      {error ? (
        <p className="error-message">{error}</p>
      ) : user ? (
        <>
          <div className="user-info">
            <img src={user.profile_picture} alt="Profile" className="profile-picture" />
            <h1 className="username">{user.user_username}</h1>
            <p className="gems">
              {user.gems}  <FontAwesomeIcon icon={faGem} /> 
            </p>
          </div>
          <div className="tasks-container">
            {tasks.map((task, index) => (
              <TaskCard 
                key={index}
                user_id={user_id}
                icon={`//bot.gemsfarm.online/bots_logo/${task.link_uri}.jpg`}
                name={task.link_name}
                description={task.link_description}
                link_url={task.link_url}
                link_uri={task.link_uri}
                reward={task.reward}
                completed={false}
                onClaim={handleClaim} // Передаем функцию onClaim
                cardStyle={{ width: '95%' }} // Передаем стиль ширины карточки
              />
            ))}
            {hasMoreTasks && (
              <Link to="/tasks" className="more-button">More</Link>
            )}
          </div>
        </>
      ) : (
        <p className="loading-message">Loading...</p>
      )}
    </div>
  );
}

export default Home;
