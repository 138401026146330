// AdminPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import config from '../config';

const Container = styled.div`
  padding: 20px;
  color: #fff;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 10px;
  width: 300px;
  border-radius: 5px;
  border: none;
`;

const SubmitButton = styled.button`
  background-color: #6200ee;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background-color: #3700b3;
  }
`;

const LinkTable = styled.table`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 70px;
  border-collapse: collapse;
`;

const LinkRow = styled.tr`
  border: 1px solid #222;
  padding: 8px;
`;

const LinkCell = styled.td`
  border: 1px solid #222;
  padding: 8px;
`;

const AdminPage = ({ user_id }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const [reward, setReward] = useState(5);
  const [links, setLinks] = useState([]);
  const [editingLink, setEditingLink] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      fetchLinks();
    }
  }, [isAuthenticated]);

  const fetchLinks = () => {
    axios.get(`${config.apiUrl}links`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
      })
      .then(response => {
        setLinks(response.data);
      })
      .catch(error => {
        console.error('Error fetching links:', error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const url = new URL(linkUrl);
    const linkUri = url.pathname.split('/')[1];
    let linkBaseUrl = `${url.origin}${url.pathname}`;
    let linkVar = '';

    if (url.searchParams.has('startapp')) {
      linkBaseUrl += '?startapp=';
      linkVar = url.searchParams.get('startapp');
    } else if (url.searchParams.has('start')) {
      linkBaseUrl += '?start=';
      linkVar = url.searchParams.get('start');
    }

    const data = {
      user_id,
      link_name: name,
      link_description: description || '',
      link_uri: linkUri,
      link_url: linkUrl,
      link_base_url: linkBaseUrl,
      link_var: linkVar,
      reward: reward,
      clicks: 0,
      onoff: 1
    };

    axios.post(`${config.apiUrl}add_link`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        alert('Link added successfully');
        setName('');
        setDescription('');
        setLinkUrl('');
        setReward(10);
        fetchLinks();
      })
      .catch(error => {
        console.error('Error adding link:', error);
        alert('Error adding link');
      });
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();

    const data = {
      id: editingLink.id,
      link_name: editingLink.link_name,
      link_description: editingLink.link_description,
      link_url: editingLink.link_url,
      reward: editingLink.reward,
      onoff: editingLink.onoff,
      global_timer_hr: editingLink.global_timer_hr,
    };

    axios.post(`${config.apiUrl}update_link`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
      .then(response => {
        alert('Link updated successfully');
        setEditingLink(null);
        fetchLinks();
      })
      .catch(error => {
        console.error('Error updating link:', error);
        alert('Error updating link');
      });

  };

  const handleLogin = (e) => {
    e.preventDefault();

    axios.post(`${config.apiUrl}admin/login`, { username, password }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        setIsAuthenticated(true);
        localStorage.setItem('token', response.data.token); // Сохраняем токен
      })
      .catch(error => {
        console.log('Login error config:', error.config);
        console.log('Login error response:', error.response?.data);
        alert('Неверное имя пользователя или пароль');
      });

  };

  const startEditing = (link) => {
    setEditingLink({ ...link });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingLink((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Container>
      {isAuthenticated ? (
        <>
          <Form onSubmit={handleSubmit}>
            <Input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
            <Input type="text" placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
            <Input type="text" placeholder="Link URL" value={linkUrl} onChange={(e) => setLinkUrl(e.target.value)} required />
            <Input type="number" placeholder="Reward" value={reward} onChange={(e) => setReward(e.target.value)} required />
            <SubmitButton type="submit">Add Link</SubmitButton>
          </Form>

          {editingLink && (
            <Form onSubmit={handleEditSubmit}>
              <Input type="text" name="link_name" placeholder="Name" value={editingLink.link_name} onChange={handleInputChange} required />
              <Input type="text" name="link_description" placeholder="Description" value={editingLink.link_description} onChange={handleInputChange} />
              <Input type="text" name="link_url" placeholder="Link URL" value={editingLink.link_url} onChange={handleInputChange} required />
              <Input type="number" name="reward" placeholder="Reward" value={editingLink.reward} onChange={handleInputChange} required />
              <Input type="number" name="onoff" placeholder="On/Off" value={editingLink.onoff} onChange={handleInputChange} required />
              <Input type="number" name="global_timer_hr" placeholder="Global Timer (hr)" value={editingLink.global_timer_hr} onChange={handleInputChange} required />
              <SubmitButton type="submit">Update Link</SubmitButton>
            </Form>
          )}

          <LinkTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>URL</th>
                <th>Reward</th>
                <th>On/Off</th>
                <th>Global Timer (hr)</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {links.map((link) => (
                <LinkRow key={link.id}>
                  <LinkCell>{link.link_name}</LinkCell>
                  <LinkCell>{link.link_description}</LinkCell>
                  <LinkCell>{link.link_url}</LinkCell>
                  <LinkCell>{link.reward}</LinkCell>
                  <LinkCell>{link.onoff}</LinkCell>
                  <LinkCell>{link.global_timer_hr}</LinkCell>
                  <LinkCell>
                    <button onClick={() => startEditing(link)}>Edit</button>
                  </LinkCell>
                </LinkRow>
              ))}
            </tbody>
          </LinkTable>
        </>
      ) : (
        <Form onSubmit={handleLogin}>
          <Input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <SubmitButton type="submit">Login</SubmitButton>
        </Form>
      )}
    </Container>
  );
};

export default AdminPage;
